.image-upload {
  margin-bottom: 1rem;

  input {
    all: unset;
    width: 100%;
  }

  img {
    width: 50%;
  }
}
