.destination-header {
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  &__container {
    background: linear-gradient(0deg, rgba($neutral-800, 1), rgba($neutral-800, 0.3));
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--title,
    &--subtitle,
    &--back {
      margin: 0;
      color: $white;
    }

    &--back {
      position: absolute;
      top: 2rem;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}
