input,
textarea {
  border-radius: $border-radius;
  border: 1px solid $neutral-800;
}

input {
  height: 2rem;
}

label {
  margin-bottom: 8px;
}
