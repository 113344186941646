.container {
  display: grid;
  margin-left: 1rem;
  margin-right: 1rem;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;

  @include sm {
    width: 540px;
  }

  @include md-min {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  @include lg-min {
    width: 960px;
  }

  @include xl-min {
    width: 1140px;
  }

  @include xxl-min {
    width: 1320px;
  }

  @for $i from 1 through 12 {
    .col-#{$i} {
      grid-column: span #{$i};
    }
  }

  @for $i from 1 through 12 {
    .col-md-#{$i} {
      @include md-min {
        grid-column: span #{$i};
      }
    }
  }

  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      @include lg-min {
        grid-column: span #{$i};
      }
    }
  }

  @for $i from 1 through 12 {
    .col-xl-#{$i} {
      @include xl-min {
        grid-column: span #{$i};
      }
    }
  }
}
