.login-page {
  background-color: $primary;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__card {
    background-color: $neutral-200;
    border-radius: $border-radius;
    padding: 1.5rem;
    width: 80%;
    max-width: 1000px;

    &--error {
      color: $danger;
      font-weight: bold;
    }
  }

  &__form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .login-form {
    display: flex;
    flex-direction: column;

    &__signup {
      margin-top: 1rem;
    }
  }
}
