.navigation {
  background-color: $neutral-200;
  border-radius: $border-radius-big;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: $box-shadow;
  margin: 15px;
  position: fixed;
  bottom: 0;

  @include lg-max {
    width: calc(100% - 30px);
  }

  @include lg-min {
    position: relative;
    margin: 0;
    border-radius: 0;
    background-color: $primary;
    padding: 0 30px;
  }

  &__item {
    text-decoration: unset;

    &:first-child {

      @include lg-min {
        margin-right: auto;
      }
    }

    svg:hover {
      fill: $primary;
    }

    &--icon {
      display: flex;
      justify-content: center;
      align-items: center;

      @include lg-min {
        display: none;
      }
    }

    &--text {
      display: none;
      font-weight: bold;
      color: $black;

      @include lg-min {
        display: block;
      }
    }
  }

  &__button {
    background-color: $primary;
    border-radius: $border-radius-big;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include lg-min {
      background-color: transparent;
    }

    &:hover,
    svg:hover {
      background-color: $primary-900;
      fill: $black;

      @include lg-min {
        background-color: transparent;
      }
    }
  }
}
