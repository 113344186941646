.destination-detail-page {

  &__toolbar {
    display: flex;
    justify-content: flex-end;

    svg {
      fill: $primary;
      cursor: pointer;
    }
  }

  &__description,
  &__toolbar {
    margin-top: 2rem;
  }
}
