.search-select {
  input {
    width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;
    border: 1px solid $neutral-800;
    max-height: 150px;
    overflow: scroll;
    border-radius: $border-radius;
    background-color: $white;
    position: absolute;
    margin-top: 0;

    li {
      padding: 8px;
      border-bottom: 1px solid $neutral-800;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: $neutral-300;
        cursor: pointer;
      }
    }
  }

  input:focus + ul {
    display: block;
  }
}
