.destination {
  border-radius: $border-radius;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__container {
    background: linear-gradient(0deg, rgba($neutral-800, 1), rgba($neutral-800, 0.3));
    border-radius: $border-radius;
    height: 140px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: $white;
    font-weight: bold;
    text-decoration: none;

    &--label {
      padding: 5px;
      background-color: rgba($neutral-800, 0.5);
      border-radius: $border-radius;
    }

    p {
      margin: 0;
    }
  }
}
