.button {
  all: unset;
  padding: 8px 1rem;
  border-radius: $border-radius;
  font-weight: bold;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &.primary {
    background-color: $primary;

    &:hover {
      background-color: $primary-900;
    }
  }

  &.secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
      background-color: $secondary-900;
    }
  }
}
