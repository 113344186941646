@import url("https://use.typekit.net/ayb4dkc.css");

html,
body {
  font-family: "mislab-std", serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}
