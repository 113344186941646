$neutral-200: #EFE8F0;
$neutral-300: #CBD5E1;
$neutral-800: #1E293B;

$primary: #E8B028;
$primary-900: #CC9B23;

$secondary: #2763E8;
$secondary-900: #2257CC;

$danger: #CD1515;

$white: #FFFFFF;
$black: #000000;
