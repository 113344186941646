.toggle {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2rem;
  margin-bottom: 1rem;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .toggle__slider {
      background-color: $primary;
    }

    &:checked + .toggle__slider::before {
      transform: translateX(2rem);
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $neutral-300;
    transition: .4s;
    border-radius: $border-radius-big;

    &::before {
      position: absolute;
      content: "";
      height: 1.5rem;
      width: 1.5rem;
      left: 0.25rem;
      bottom: 0.25rem;
      background-color: $white;
      transition: .4s;
      border-radius: $border-radius-big;
    }
  }
}
