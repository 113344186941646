.add-destination,
.update-destination {
  position: absolute;
  border-radius: $border-radius;
  background-color: $neutral-200;
  min-width: 50vw;
  width: 75%;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &--title {
      margin: 0;
    }

    &--close {
      all: unset;
      cursor: pointer;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      margin-bottom: 1rem;
    }
  }
}
